import React, { useState } from 'react';
import { InputField, Logo, Button, eyeVisible, eyeInvisible } from 'ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useAuth } from '../../utils/auth';
import { toastError } from 'toast-schema';
import { useToast } from '@chakra-ui/react';
import { loginSchema } from 'validations';
import { USER_NOT_FOUND, WRONG_PASSWORD_ERROR } from 'constant';
import { RestrictedRoute } from '../../utils/auth';
import { ISignUpEmailFormInput } from 'models';

const AdminLogin: React.FC = () => {
  const { t } = useTranslation('common');

  const { setLoading, setAuthenticated, setUser, logIn } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  });

  const toast = useToast();

  const loginSubmit = async (data: ISignUpEmailFormInput) => {
    try {
      await logIn(data.email, data.password);
    } catch (error: any) {
      if (error.message === WRONG_PASSWORD_ERROR) {
        toastError({
          toastId: 'login',
          toast,
          description: t('Your email address or password does not match.'),
        });
        setLoading(false);
        setUser(null);
        setAuthenticated(false);
        return;
      }
      if (error.message === USER_NOT_FOUND) {
        toastError({
          toastId: 'login',
          toast,
          description: t('The account doesn’t exists'),
        });
        setLoading(false);
        setUser(null);
        setAuthenticated(false);
        return;
      }
      toastError({
        toastId: 'login',
        toast,
        description: t('Error occurred'),
      });
      setLoading(false);
      setUser(null);
      setAuthenticated(false);
    }
  };

  return (
    <>
      <div className="m-auto my-5 flex h-screen min-h-screen w-[95%] flex-col items-center justify-center bg-slate-100 p-3 shadow-lg">
        <div className="flex items-center justify-center w-full max-w-md mx-auto">
          <Logo />
        </div>
        <div className="flex flex-col items-center justify-center w-full mt-8 shrink-0">
          <form
            onSubmit={handleSubmit(loginSubmit)}
            className="text-gray-5 min-w-[370px]"
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputField
                  name="your email address"
                  field={field}
                  size={'lg'}
                  label={t('Email Address')}
                  // placeholder="email@oirecords.com"
                  error={errors.email?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <InputField
                  name="password"
                  label={t('Password')}
                  field={field}
                  size={'lg'}
                  type={showPassword ? 'text' : 'password'}
                  // placeholder="********"
                  iconRight={showPassword ? eyeVisible : eyeInvisible}
                  onClickRightIcon={() => setShowPassword(!showPassword)}
                  error={errors.password?.message}
                />
              )}
            />
            <div className="mx-auto mt-4 flex w-[50%] justify-center">
              <Button
                disabled={isSubmitting}
                name={t('Login')}
                type="submit"
                buttonType="secondary"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RestrictedRoute(AdminLogin);

export async function getServerSideProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}
